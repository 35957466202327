<template>
  <div>
    <div class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>风险分析</div>

      <spread-table v-loading="loading" :tableHeight="tableHeight" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { findRiskAnalysis } from "./exponentApi";
export default defineComponent({
  name: "risk-analysis",
  components: {
    SpreadTable,
  },
  setup() {
    onMounted(() => {
      getUserList()
    })
    let data = reactive({
      drafrList: [
        { name: '小组排名', text: 'id' },
        { name: '小组名称', text: 'groupName' },
        { name: '收益率标准差', text: 'standardDeviationOfYield' },
        { name: '最大回撤', text: 'maximumBacktest' },
        { name: '单月最低回报', text: 'minimumReturnPerMonth' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],
      tableHeight: 450,
    })
    let getUserList = () => {
      data.loading = true
      let parame = {
        classId: JSON.parse(sessionStorage.getItem('classId')),
        // benchmark: data.benchmark,
        benchmark: 'SZ50',
        page: data.currentPage,
        pageSize: data.pageSize,
      }
      findRiskAnalysis(parame).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          data.spreadTable = newRes.pageObject
          data.total = newRes.total
          setTimeout(() => {
            data.loading = false
          }, 300)
        }
      })
    }
    let sizeChange = (val) => {
      data.pageSize = val
      getUserList(data)
    }
    let currentChange = (val) => {
      data.currentPage = val
      getUserList(data)
    }

    return {
      ...toRefs(data),
      sizeChange,
      currentChange,
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background:  #3B6EFB;
}
</style>